/*!**********************************************************************************************************************************************************************************************************************************************************************************************************************************!*\
  !*** css ./node_modules/css-loader/dist/cjs.js??clonedRuleSet-13.use[1]!./node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-13.use[2]!./node_modules/resolve-url-loader/index.js??clonedRuleSet-13.use[3]!./node_modules/sass-loader/dist/cjs.js??clonedRuleSet-13.use[4]!./templates/rocket/submodules/social/social.scss ***!
  \**********************************************************************************************************************************************************************************************************************************************************************************************************************************/
.instagram-feed {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  height: 300px;
  overflow-x: scroll;
  overflow-y: hidden;
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
}
.instagram-feed::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}
.instagram-feed .instagram-post {
  position: relative;
  height: 100%;
  width: 20vw;
  aspect-ratio: 1;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
@media (max-width: 768px) {
  .instagram-feed .instagram-post {
    width: 33vw;
  }
}
.instagram-feed .instagram-post .instagram-post-content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: none;
  padding: 1rem;
  color: var(--color-body);
  font-size: 12px;
  text-decoration: none;
  word-break: break-word;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: var(--color-darker);
}
.instagram-feed .instagram-post video {
  aspect-ratio: 4/3;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  height: 100%;
}
.instagram-feed .instagram-post:hover .instagram-post-content {
  display: flex;
}
